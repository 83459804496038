<template>
  <div
    v-if="$store.getters.getWMSPICKINGTableData"
    class="custom-content-height"
  >
    <v-data-table
      fixed-header
      light
      dense
      :height="getvh(57)"
      :items="items"
      :headers="headers"
      :items-per-page="itemPerPage"
      class="mx-2 mt-0 font-weight-bold"
      hide-default-footer
      mobile-breakpoint="300"
    >
      <!--    no-data-->
      <template v-slot:no-data> NO DATA HERE! </template>
      <template v-slot:no-results> NO RESULTS HERE! </template>
      <template v-slot:top>
        <div
          class="ma-0 mb-10 pa-0 pt-2 d-flex justify-space-between align-center flex-wrap bg-white position-sticky top-0 zindex-5"
          :class="{ 'flex-column': $vuetify.breakpoint.xsOnly }"
        >
          <datatable-detail
            v-if="!!table_properties"
            class="col-6 col-md-10 col-sm-9"
            :table_properties="table_properties"
          ></datatable-detail>
        </div>
      </template>
      <template
        v-for="(head, i) in headers"
        v-slot:[`item.`+head.value]="{ item, header, value }"
      >
        <ComponentSelector
          v-bind:key="i"
          :item="item"
          :header="header"
          :value="value"
        ></ComponentSelector>
      </template>
      <template #item.action="{ item, value }">
        <b-button
          @click="() => $refs.pickingScanner.toggleModal(item)"
          variant="-export-filter"
        >
          Scan
        </b-button>
      </template>
    </v-data-table>
    <component
      ref="pickingScanner"
      :is="scanPicking"
      :pageLoader="pageLoad"
      :refresher="updateTableContent"
    ></component>
    <Pagination
      v-if="items.length"
      :setitemPerPage="setItemPerPage"
      :getitemPerPage="itemPerPage"
      :getpageNumber="getpageNumber"
      :setpageNumber="setpageNumber"
      :totalItems="totalItems"
      :pageCount="pageCount"
      :updateTableContent="updateTableContent"
    ></Pagination>
  </div>
</template>

<script>
import ComponentSelector from "@/own/components/datatable/ComponentSelector";
import Pagination from "@/own/components/pagination/Pagination.vue";
import DatatableDetail from "@/own/components/datatable/DatatableDetail";
import ScanPicking from "@/own/components/warehouseManagement/picking/ScanPicking";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import {
  UPDATE_TABLE_DATA,
  SET_TABLE_PERPAGE,
  SET_TABLE_PAGE,
} from "@/core/services/store/picking.module";
import getPageWidthHeight from "@/own/mixins/getPageWidthHeight";

export default {
  name: "DataTable",
  mixins: [getPageWidthHeight],
  components: { ScanPicking, ComponentSelector, DatatableDetail, Pagination },
  data: () => ({
    scanPicking: ScanPicking,
  }),
  computed: {
    table_properties: function () {
      return this.$store.getters.getWMSPICKINGTableProperties;
    },
    headers: function () {
      /**
       * @type {[
          {
              value: string,
              text: string,
              type: string,
              sortable: string,
              exportable: string,
              visible: string,
              mobile_visible: string,
              align: string,
              itemClass: string,
              width: string,
              class: string,
              export_order: integer,
            }
                ]}
       */
      let headers;

      if (this.$store.getters.getWMSPICKINGTableHeaders) {
        headers = this.$store.getters.getWMSPICKINGTableHeaders.filter(
          (col) => col.visible === true
        );
      }
      headers.forEach((header) => {
        header.class =
          "text-muted fw-bolder font-size-sm text-uppercase poppins-ls";
        header.itemClass = "second-text";
        header.width = undefined;
        if (header.value === "action") {
          header.class =
            "text-center text-muted fw-bolder font-size-sm text-uppercase poppins-ls";
        }
      });
      return headers;
    },
    items: function () {
      let items = null;
      if (this.$store.getters.getWMSPICKINGTableData) {
        items = this.$store.getters.getWMSPICKINGTableData.data;
      }
      return items || [];
    },
    itemPerPage: function () {
      return this.$store.getters.getWMSPICKINGTableState.per_page;
    },
    getpageNumber: function () {
      return this.$store.getters.getWMSPICKINGTableState.page;
    },
    totalItems: function () {
      return this.$store.getters.getWMSPICKINGTableData.total;
    },
    pageCount: function () {
      return this.$store.getters.getWMSPICKINGTableData.last_page;
    },
  },
  methods: {
    // pagination
    setItemPerPage(val) {
      this.$store.commit(SET_TABLE_PERPAGE, val);
      this.$store.commit(SET_TABLE_PAGE, 1);
    },
    setpageNumber(val) {
      this.$store.commit(SET_TABLE_PAGE, val);
    },
    // pagination end
    pageLoad(state) {
      this.$store.commit(SET_PAGE_LOADING, state);
    },
    async updateTableContent() {
      await this.$store
        .dispatch(UPDATE_TABLE_DATA, {
          warehouse_id: this.$store.getters.getSelectedWarehouse,
          ...this.$store.getters.getWMSPICKINGTableState,
        })
        .then(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
  },
};
</script>
