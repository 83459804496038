import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

// action types
export const UPDATE_TABLE_STRUCTURE = "getWMSPICKINGTableStructure";
export const UPDATE_TABLE_DATA = "updateWMSPICKINGTableData";

// mutation types
export const SET_TABLE_STRUCTURE = "setWMSPICKINGTableStructure";
export const SET_TABLE_DATA = "setWMSPICKINGTableData";
export const SET_TABLE_FILTER = "setWMSPICKINGTableFilter";
export const SET_TABLE_PAGE = "setWMSPICKINGTablePage";
export const SET_TABLE_PERPAGE = "setWMSPICKINGTablePerPage";
export const SET_TABLE_SORTBY = "setWMSPICKINGTableSortBy";
export const SET_TABLE_SORTORDER = "setWMSPICKINGTableSortOrder";
export const SET_CURRENT_ITEM = "setWMSPICKINGCurrentItem";
// export const SET_NEXT_ITEM = "setWMSPICKINGNextItem";
// export const SET_PREVIOUS_ITEM = "setWMSPICKINGPreviousItem";

const state = {
  table_structure: {},
  table_data: {},
  table_state: {
    page: 1,
    per_page: 50,
    sortBy: "id",
    sortOrder: "desc",
    filters: [],
  },
  current_item: null,
};

const getters = {
  getWMSPICKINGTableProperties(state) {
    return state.table_structure.properties;
  },
  // getWMSPICKINGTablePermissions(state) {
  //   return state.table_structure.permissions;
  // },
  getWMSPICKINGTableState(state) {
    return state.table_state;
  },

  getWMSPICKINGTableHeaders(state) {
    return state.table_structure.columns;
  },

  getWMSPICKINGTableData(state) {
    return state.table_data.data;
  },
  getWMSPICKINGCurrentItem(state) {
    return state.current_item;
  },
  getWMSPICKINGNextItem(state, getters) {
    const currentItem = getters.getWMSPICKINGCurrentItem;
    if (currentItem) {
      //get current item index
      const currentItemIndex = state.table_data.data.data.findIndex(
        (item) => item.id === currentItem.id
      );
      //get an sub-array of next available items from (current index+1) to end of the list
      const nextItems = [...state.table_data.data.data].slice(
        currentItemIndex + 1
      );
      //return the next item with same location as current item
      return nextItems.find(
        (item) => item.location_label === currentItem.location_label
      );
    }
    return null;
  },
  getWMSPICKINGPreviousItem(state, getters) {
    const currentItem = getters.getWMSPICKINGCurrentItem;
    if (currentItem) {
      //get current item index
      const currentItemIndex = state.table_data.data.data.findIndex(
        (item) => item.id === currentItem.id
      );
      //get an sub-array of previous available items from current index to beginning of the list (reverse)
      const previousItems = [...state.table_data.data.data].slice(
        0,
        currentItemIndex
      );
      //return the previous item with same location as current item
      return previousItems.findLast(
        (item) => item.location_label === currentItem.location_label
      );
    }
    return null;
  },
};

const actions = {
  [UPDATE_TABLE_STRUCTURE](context) {
    return new Promise((resolve) => {
      ApiService.post("/warehouse_management/picking")
        .then(({ data }) => {
          context.commit(SET_TABLE_STRUCTURE, data);
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [UPDATE_TABLE_DATA](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post("/warehouse_management/picking/datatable", payload)
        .then(({ data }) => {
          context.commit(SET_TABLE_DATA, data);
          resolve(data);
          context.commit(SET_PAGE_LOADING, false);
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
};

const mutations = {
  [SET_TABLE_FILTER](state, filter) {
    state.table_state.filters = filter;
  },
  [SET_TABLE_PAGE](state, page) {
    state.table_state.page = page;
  },
  [SET_TABLE_PERPAGE](state, perPage) {
    state.table_state.per_page = perPage;
  },
  [SET_TABLE_SORTBY](state, sortBy) {
    state.table_state.sortBy = sortBy;
  },
  [SET_TABLE_SORTORDER](state, sortOrder) {
    state.table_state.sortOrder = sortOrder;
  },
  [SET_TABLE_STRUCTURE](state, table_structure) {
    state.table_structure = table_structure.data;
  },
  [SET_TABLE_DATA](state, table_data) {
    state.table_data = table_data;
  },
  [SET_CURRENT_ITEM](state, id) {
    if (id) {
      state.current_item = state.table_data.data.data.find(
        (item) => item.id === id
      );
    } else state.current_item = null;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
